import React from 'react';

function Footer() {
  return (
    <footer className="bg-black">
      <nav className="flex justify-center max-w-4xl p-4 mx-auto text-sm md:p-8">
        <p className="text-white ">
          Powered By{' '}
          <a
            className="font-bold no-underline"
            href="https://exit35.dev"
            target="_blank"
            rel="noopener noreferrer"
          >
            Exit 35, LLC
          </a>
        </p>
      </nav>
    </footer>
  );
}

export default Footer;
