import { Link } from 'gatsby';
import React, { useState } from 'react';

import logo from '../images/logos/logo_transparent_xs.png';

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <header className="bg-black">
      <div className="flex flex-wrap items-center justify-between max-w-4xl py-2 px-4 lg:px-0 mx-auto">
        <Link to="/">
          <h1 className="flex items-center text-white no-underline">
            <img alt="TensorFlow Art's logo" className="block mx-auto h-16" src={logo} />
          </h1>
        </Link>

        <button
          className="flex items-center block px-3 py-2 text-white border border-white rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
          type="button"
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? 'block' : 'hidden'
          } md:block md:flex md:items-center w-full md:w-auto`}
        >
          {[
            {
              route: '/more-examples',
              title: 'More Examples',
            },
            {
              route: '/try-it-now',
              title: 'Try It Now',
            },
          ].map((link) => (
            <Link
              className="block mt-4 text-white no-underline md:inline-block md:mt-0 md:ml-6"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
